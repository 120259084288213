<script lang="ts" setup>
import { useCookieConsentStore } from '~/stores/useCookieConsentStore'
import { usePlayerStore } from '~/stores/usePlayerStore'

const route = useRoute()

watchEffect(() => {
  if (import.meta.client && window.gtag) {
    if (['radio-id', 'podcast-podcastId', 'podcast-podcastId-episodes-episodeId'].includes(route.name)) {
      return
    }

    if (route.path.startsWith('/bible')) {
      window.gtag('event', 'page_view', {
        content_group: 'Bible',
      })
    } else {
      window.gtag('event', 'page_view')
    }

    if (route.query?.query) {
      window.gtag('event', 'view_search_results', {
        search_term: route.query?.query,
      })
    }
  }
})

const ccStore = useCookieConsentStore()
const playerStore = usePlayerStore()

onMounted(() => {
  ccStore.fetchGDPR()
})

const consented = ref(false)

const isTargetingAccepted = computed(() => {
  if (!ccStore.gdpr) {
    return false
  }

  return ccStore.consented && ccStore.gdpr.gdprCountry && ccStore.categories.includes('targeting')
})

const isTargetingNotAccepted = computed(() => {
  if (!ccStore.gdpr) {
    return false
  }

  return ccStore.consented && ccStore.gdpr.gdprCountry && !ccStore.categories.includes('targeting')
})

const isTargetingNotRequired = computed(() => {
  if (!ccStore.gdpr) {
    return false
  }

  return ccStore.consented && !ccStore.gdpr.gdprCountry
})

watch(
  () => ccStore.consented,
  () => {
    consented.value = true
  }
)

useScript(
  {
    src: 'https://synchrobox.adswizz.com/register2.php?aw_0_req.gdpr=true',
  },
  {
    trigger: isTargetingAccepted,
  }
)

useScript(
  {
    src: 'https://cdn.adswizz.com/adswizz/js/SynchroClient2.js?aw_0_req.gdpr=true',
  },
  {
    trigger: isTargetingAccepted,
  }
)

useScript(
  {
    src: 'https://synchrobox.adswizz.com/register2.php?aw_0_req.gdpr=false',
  },
  {
    trigger: isTargetingNotAccepted,
  }
)

useScript(
  {
    src: 'https://cdn.adswizz.com/adswizz/js/SynchroClient2.js?aw_0_req.gdpr=false',
  },
  {
    trigger: isTargetingNotAccepted,
  }
)

useScript(
  {
    src: 'https://synchrobox.adswizz.com/register2.php',
  },
  {
    trigger: isTargetingNotRequired,
  }
)

useScript(
  {
    src: 'https://cdn.adswizz.com/adswizz/js/SynchroClient2.js',
  },
  {
    trigger: isTargetingNotRequired,
  }
)

useScript(
  { src: 'https://playerservices.live.streamtheworld.com/api/idsync.js?stationId=118143&bounce=true' },
  {
    trigger: consented,
  }
)

useScript(
  {
    src: 'https://play.adtonos.com/attc-uAdJ2ujapxW6xDFKk.min.js',
  },
  {
    trigger: consented,
  }
)
</script>

<template>
  <div>
    <NuxtLoadingIndicator color="#8028F8" />
    <NuxtRouteAnnouncer />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <div v-if="playerStore.media" class="z-20 fixed w-full bottom-0 left-0 drop-shadow-[0_0_50px_rgba(0,0,0,0.25)]">
      <LazyPlayerCompact v-if="$viewport.isLessThan('md')" />
      <LazyPlayer v-else />
    </div>

    <LazyCookieConsent v-if="ccStore.isConsentRequired" />
    <LazyPlayerInput />
    <LazyToaster />
  </div>
</template>
