
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46segments_93yQXs3VRHyBaeCKKBMtsCvYIulpiWMqozS8Q1rhD1pWgMeta } from "/app/pages/[...segments].vue?macro=true";
import { default as _91chapterNumber_93jF3fk7pfW_khF0TZPqYRp08FqmwfI75xrad2q56CxIwMeta } from "/app/pages/bible/[translation]/[bookCode]/[chapterNumber].vue?macro=true";
import { default as indexm55kj0VP9Qk3ZykaftQGpZkKHcDKMglpTk8p_45UJ_S2YMeta } from "/app/pages/bible/[translation]/[bookCode]/index.vue?macro=true";
import { default as index_kdxMA6Aw2QJoDDoV_Hziol54oJUBn_45LmXNaNJ6bjOQMeta } from "/app/pages/bible/[translation]/index.vue?macro=true";
import { default as _91testament_93Roj4j5XFeoVukVk2xC00FApY_oSa08HlMU_VmCzpDk4Meta } from "/app/pages/bible/[translation]/testament/[testament].vue?macro=true";
import { default as bibledJv_454hjvg4FxcxoZF3m_45XZrbYhvYvgLzB4wRatrG4jwMeta } from "/app/pages/bible.vue?macro=true";
import { default as exploreNi35HV1vbB54I5yn2vlXRNwcb8TmGkArbynjdTJapAoMeta } from "/app/pages/explore.vue?macro=true";
import { default as homeATfYOZM_NGzagjGhIsvcT2b9_cFGO0yRVLFd_45gK_451EkMeta } from "/app/pages/home.vue?macro=true";
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as _91episodeId_93bqon8nbKRsA89vIBUNuA9mlyTKz1MmwRh1OjM6XpZ2sMeta } from "/app/pages/podcast/[podcastId]/episodes/[episodeId].vue?macro=true";
import { default as indexhk8q6a4A8PHPuZsjWP74M0hSH_hT8qYDEqBGYinJaIMMeta } from "/app/pages/podcast/[podcastId]/index.vue?macro=true";
import { default as _91category_93p9B_86MmYDO1VW6r4oMhfZlEDEWqB_xzcvSAVzicMPIMeta } from "/app/pages/podcasts/categories/[category].vue?macro=true";
import { default as indexm9gggRzlYwGJYryiIHPwdrKhL_45lMCJz4c5RjRd2rZi4Meta } from "/app/pages/podcasts/categories/index.vue?macro=true";
import { default as _91country_93pPTm2kNFnxYa3MJPK8Wcv_45aSUWUX9JTHCl0rnwTAZHsMeta } from "/app/pages/podcasts/countries/[country].vue?macro=true";
import { default as indexSx0cIs8OXirGRWDDjBnCj_45K5dGeGbjUim5ZDIFURfKYMeta } from "/app/pages/podcasts/countries/index.vue?macro=true";
import { default as indexwk_45PYCByCydXJjfnfsNw7FQNiMJIE_45UQ0jOAlSk_UPAMeta } from "/app/pages/podcasts/index.vue?macro=true";
import { default as _91language_936k3MzlRECcy29UDGze4eZR9lkvZRV0qffTUT5lZU_hAMeta } from "/app/pages/podcasts/languages/[language].vue?macro=true";
import { default as indexAXH9BbVlZlm57hHwbMeVNRNHeZN_45IpI3hVNtLj5YDQoMeta } from "/app/pages/podcasts/languages/index.vue?macro=true";
import { default as podcastsL2Ek7q0eOT9hcC4lgp0QRVpIFZmvVxnJjNbpvuVoNwEMeta } from "/app/pages/podcasts.vue?macro=true";
import { default as _91id_93kVPEeMedZwIG7ljmjNMBgmTOkLHEu81ZvsrIRztXxgkMeta } from "/app/pages/radio/[id].vue?macro=true";
import { default as _91country_93ddtaudn8sElBTcrh5IP6r1khkPKAkJ6wwEz_dHMGXdAMeta } from "/app/pages/radio/countries/[country].vue?macro=true";
import { default as indexcKLQMqcCm7lXFFh5DGwJcEsoJk_45ZC_z2gfkldKYgvuoMeta } from "/app/pages/radio/countries/index.vue?macro=true";
import { default as _91genre_93HZBIsbIBq9cYGeqiwzWNYJg6XUAeiWRm58Vt4dKSmTEMeta } from "/app/pages/radio/genres/[genre].vue?macro=true";
import { default as indexINgTSoQXCb3WBqhy9VZ5S_45D8jU_fwK1sRIVf76q_45aSkMeta } from "/app/pages/radio/genres/index.vue?macro=true";
import { default as indexBdBBaBCx2e1MyiNUlI81_45VZChk_3hPRH_45WKmpvu1eaMMeta } from "/app/pages/radio/index.vue?macro=true";
import { default as _91language_93PvE3sL_456aB5x2NGbhSbe05YPzxupw02UOBXfznlXlMwMeta } from "/app/pages/radio/languages/[language].vue?macro=true";
import { default as index_45asgDcljYJuM47qRcnGa6OEMKtug_hTKbLRvQ_45_45XTFUMeta } from "/app/pages/radio/languages/index.vue?macro=true";
import { default as radioOHJphTHrHmVovFuroAnIbCjFBYDwcBQLBvgdEdm0lc0Meta } from "/app/pages/radio.vue?macro=true";
import { default as _91_46_46_46slug_93vq6SuunkwjZM4JB74MxCsigu8bWOVdx_IjwSYpn2aCcMeta } from "/app/pages/show/[...slug].vue?macro=true";
import { default as component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "segments",
    path: "/:segments(.*)*",
    component: () => import("/app/pages/[...segments].vue")
  },
  {
    name: "bible",
    path: "/bible",
    component: () => import("/app/pages/bible.vue"),
    children: [
  {
    name: "bible-translation-bookCode-chapterNumber",
    path: ":translation()/:bookCode()/:chapterNumber()",
    component: () => import("/app/pages/bible/[translation]/[bookCode]/[chapterNumber].vue")
  },
  {
    name: "bible-translation-bookCode",
    path: ":translation()/:bookCode()",
    component: () => import("/app/pages/bible/[translation]/[bookCode]/index.vue")
  },
  {
    name: "bible-translation",
    path: ":translation()",
    component: () => import("/app/pages/bible/[translation]/index.vue")
  },
  {
    name: "bible-translation-testament-testament",
    path: ":translation()/testament/:testament()",
    meta: _91testament_93Roj4j5XFeoVukVk2xC00FApY_oSa08HlMU_VmCzpDk4Meta || {},
    component: () => import("/app/pages/bible/[translation]/testament/[testament].vue")
  }
]
  },
  {
    name: "explore",
    path: "/explore",
    component: () => import("/app/pages/explore.vue")
  },
  {
    name: "home",
    path: "/home",
    component: () => import("/app/pages/home.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "podcast-podcastId-episodes-episodeId",
    path: "/podcast/:podcastId()/episodes/:episodeId()",
    component: () => import("/app/pages/podcast/[podcastId]/episodes/[episodeId].vue")
  },
  {
    name: "podcast-podcastId",
    path: "/podcast/:podcastId()",
    component: () => import("/app/pages/podcast/[podcastId]/index.vue")
  },
  {
    name: podcastsL2Ek7q0eOT9hcC4lgp0QRVpIFZmvVxnJjNbpvuVoNwEMeta?.name,
    path: "/podcasts",
    component: () => import("/app/pages/podcasts.vue"),
    children: [
  {
    name: "podcasts-categories-category",
    path: "categories/:category()",
    component: () => import("/app/pages/podcasts/categories/[category].vue")
  },
  {
    name: "podcasts-categories",
    path: "categories",
    component: () => import("/app/pages/podcasts/categories/index.vue")
  },
  {
    name: "podcasts-countries-country",
    path: "countries/:country()",
    component: () => import("/app/pages/podcasts/countries/[country].vue")
  },
  {
    name: "podcasts-countries",
    path: "countries",
    component: () => import("/app/pages/podcasts/countries/index.vue")
  },
  {
    name: "podcasts",
    path: "",
    component: () => import("/app/pages/podcasts/index.vue")
  },
  {
    name: "podcasts-languages-language",
    path: "languages/:language()",
    component: () => import("/app/pages/podcasts/languages/[language].vue")
  },
  {
    name: "podcasts-languages",
    path: "languages",
    component: () => import("/app/pages/podcasts/languages/index.vue")
  }
]
  },
  {
    name: radioOHJphTHrHmVovFuroAnIbCjFBYDwcBQLBvgdEdm0lc0Meta?.name,
    path: "/radio",
    component: () => import("/app/pages/radio.vue"),
    children: [
  {
    name: "radio-id",
    path: ":id()",
    meta: _91id_93kVPEeMedZwIG7ljmjNMBgmTOkLHEu81ZvsrIRztXxgkMeta || {},
    component: () => import("/app/pages/radio/[id].vue")
  },
  {
    name: "radio-countries-country",
    path: "countries/:country()",
    component: () => import("/app/pages/radio/countries/[country].vue")
  },
  {
    name: "radio-countries",
    path: "countries",
    component: () => import("/app/pages/radio/countries/index.vue")
  },
  {
    name: "radio-genres-genre",
    path: "genres/:genre()",
    component: () => import("/app/pages/radio/genres/[genre].vue")
  },
  {
    name: "radio-genres",
    path: "genres",
    component: () => import("/app/pages/radio/genres/index.vue")
  },
  {
    name: "radio",
    path: "",
    component: () => import("/app/pages/radio/index.vue")
  },
  {
    name: "radio-languages-language",
    path: "languages/:language()",
    component: () => import("/app/pages/radio/languages/[language].vue")
  },
  {
    name: "radio-languages",
    path: "languages",
    component: () => import("/app/pages/radio/languages/index.vue")
  }
]
  },
  {
    name: "show-slug",
    path: "/show/:slug(.*)*",
    component: () => import("/app/pages/show/[...slug].vue")
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/search",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/login/",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name,
    path: "/signup/",
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  }
]