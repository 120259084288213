import { defineStore } from 'pinia'
import type { RouteResult } from '~/types'

interface PlayerMeta {
  mediaTitle?: string
  title: string
  logo: string
  route: RouteResult
  source: string
}

interface PreRollTriggers {
  impression: string
  start: string
  firstQuartile: string
  midpoint: string
  thirdQuartile: string
  complete: string
}

interface PreRoll {
  source: string
  triggers: PreRollTriggers
}

interface Media {
  id: string
  title?: string
  subTitle: string
  logo?: string
  route: RouteResult
  source: string
}

export interface StationMedia extends Media {
  streamName: string
  sourceType: 'stream' | 'streaming'
  mediaType: 'station'
}

interface PodcastMedia extends Media {
  podcastId: string
  sourceType: 'file'
  mediaType: 'episode'
}

interface ChapterMedia extends Media {
  sourceType: 'file'
  mediaType: 'chapter'
}

export const usePlayerStore = defineStore('media-player', () => {
  const media = ref<StationMedia | PodcastMedia | ChapterMedia | null>(null)
  const preRoll = ref<PreRoll | null>(null)

  const currentTime = ref(0)
  const duration = ref(0)

  const isLoading = ref(false)

  const source = ref<string | null>(null)
  const isPlaying = ref<boolean | null>(null)
  const meta = ref<PlayerMeta | null>(null)
  const volume = ref(1)

  const isStation = computed(() => media.value?.mediaType === 'station')
  const isEpisode = computed(() => media.value?.mediaType === 'episode')
  const isStreaming = computed(() => media.value?.sourceType === 'streaming')
  const isFile = computed(() => media.value?.sourceType === 'file')

  const play = () => {
    if (preRoll.value && preRoll.value.source) {
      if (source.value !== preRoll.value.source) {
        isLoading.value = true
        source.value = preRoll.value.source
      }
    } else if (media.value && media.value.source) {
      if (source.value !== media.value.source) {
        isLoading.value = true
        source.value = media.value.source
      }
    }

    isPlaying.value = true
  }

  const pause = () => {
    isLoading.value = false
    isPlaying.value = false
  }

  const stop = () => {
    isLoading.value = false
    isPlaying.value = null
    currentTime.value = 0
    // duration.value = 0
  }

  const seek = (time: number) => {
    currentTime.value = time
  }

  const rewind = () => {
    if (currentTime.value < 15) {
      currentTime.value = 0
    } else {
      currentTime.value -= 15
    }
  }

  const forward = () => {
    if (duration.value - currentTime.value < 15) {
      currentTime.value = duration.value
    } else {
      currentTime.value += 15
    }
  }

  watch(media, (media, oldMedia) => {
    if (!oldMedia || media?.id !== oldMedia.id) {
      duration.value = 0
      currentTime.value = 0
      // isLoading.value = false
      // isPlaying.value = false
    }
  })

  return {
    media,
    preRoll,
    isPlaying,
    currentTime,
    duration,
    isLoading,
    isStation,
    isEpisode,
    isStreaming,
    isFile,
    source,
    meta,
    volume,
    play,
    pause,
    stop,
    seek,
    rewind,
    forward,
  }
})
