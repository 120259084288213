let isGptLoaded = false

export default defineNuxtPlugin(() => {
  const loadGoogleAdManager = () => {
    return new Promise((resolve) => {
      if (isGptLoaded) {
        resolve(true)
        return
      }
      isGptLoaded = true

      const script = document.createElement('script')
      script.async = true
      script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js'
      script.onload = () => {
        resolve(true)
      }
      document.head.appendChild(script)

      // Initialize googletag
      window.googletag = window.googletag || { cmd: [] }
    })
  }

  return {
    provide: {
      loadGoogleAdManager,
    },
  }
})
